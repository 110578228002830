export type Operator = '+' | '-';
export type DatePart = 'ms' | 's' | 'm' | 'h' | 'd';

export default class DateUtil {
	public static compare(d1: Date, d2: Date) {
		const v1 = d1 ? new Date(d1).getTime() : 0;
		const v2 = d2 ? new Date(d2).getTime() : 0;
		return v1 - v2;
	}

	public static calculate(o: Operator, d: Date, v: number, of: DatePart) {
		var tmp = d.getTime();
		switch (of) {
			case 'd': v*= 24;
			case 'h': v*= 60;
			case 'm': v*= 60;
			case 's': v*= 1000;
			case 'ms': 
				switch (o) {
					case '+': return new Date(tmp + v);
					case '-': return new Date(tmp - v);
					default: throw new Error('param: o');
				}
			default: throw new Error('param: of');
		}
	}

    public static between(d1: Date, d2: Date, of: DatePart) {
        var tmp = d1.getTime() - d2.getTime();
		switch (of) {
			case 'd': tmp /= 24;
			case 'h': tmp /= 60;
			case 'm': tmp /= 60;
			case 's': tmp /= 1000;
			case 'ms': return tmp;
			default: throw new Error('param: of');
		}
    }

    public static format(d: Date, pattern: string) {
        if (isNaN(d.getTime())) {
            return "";
        } else if (!pattern) {
            return d.toLocaleString();
        }

        const year = d.getFullYear().toString();
        const month = (d.getMonth() + 1).toString();
        const day = d.getDate().toString();

        var v = pattern.replace("dd", day.padStart(2, "0"));
        v = v.replace("d", day);
        v = v.replace("MM", month.padStart(2, "0"));
        v = v.replace("M", month);
        v = v.replace("yyyy", year);
        v = v.replace("yy", year.slice(-2).padStart(2, "0"));

        var hour24 = d.getHours().toString();
        var hour12 = (d.getHours() > 12 ? (d.getHours() - 12) : d.getHours()).toString();
        var minute = d.getMinutes().toString();
        var second = d.getSeconds().toString();

        if (d.getHours() === 0) {
            hour12 = "12";
        }

        v = v.replace("HH", hour24.padStart(2, "0"));
        v = v.replace("H", hour24);
        v = v.replace("hh", hour12.padStart(2, "0"));
        v = v.replace("h", hour12);
        v = v.replace("mm", minute.padStart(2, "0"));
        v = v.replace("m", minute);
        v = v.replace("ss", second.padStart(2, "0"));
        v = v.replace("s", second);
        v = v.replace("a", d.getHours() > 12 ? "PM" : "AM");

        return v;
    }
}